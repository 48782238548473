import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/scss/style.scss";

import ComingSoon18 from "./pages/comingSoon18";


function App() {
  return (
    <Router>
    
       
        <Route
          path={process.env.PUBLIC_URL + "/"}
          component={ComingSoon18}
        />
       
  
    </Router>
  );
}

export default App;
